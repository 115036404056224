@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-500.eot');
  src:
    local('MuseoSansCyrl-500'),
    url('MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-500.woff') format('woff'),
    url('MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-300Italic.eot');
  src:
    local('MuseoSansCyrl-300Italic'),
    url('MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-300Italic.woff') format('woff'),
    url('MuseoSansCyrl-300Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-500Italic.eot');
  src:
    local('MuseoSansCyrl-500Italic'),
    url('MuseoSansCyrl-500Italic.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-500Italic.woff') format('woff'),
    url('MuseoSansCyrl-500Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-900.eot');
  src:
    local('MuseoSansCyrl-900'),
    url('MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-900.woff') format('woff'),
    url('MuseoSansCyrl-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-700.eot');
  src:
    local('MuseoSansCyrl-700'),
    url('MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-700.woff') format('woff'),
    url('MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-700Italic.eot');
  src:
    local('MuseoSansCyrl-700Italic'),
    url('MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-700Italic.woff') format('woff'),
    url('MuseoSansCyrl-700Italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-300.eot');
  src:
    local('MuseoSansCyrl-300'),
    url('MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-300.woff') format('woff'),
    url('MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Sans';
  src: url('MuseoSansCyrl-900Italic.eot');
  src:
    local('Museo Sans Cyrl 900 Italic'),
    local('MuseoSansCyrl-900Italic'),
    url('MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
    url('MuseoSansCyrl-900Italic.woff') format('woff'),
    url('MuseoSansCyrl-900Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

